import {
  Typography,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
} from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import BookingServices from "../../services/BookingServices";

import MoreVertIcon from "@mui/icons-material/MoreVert";

const columns = [
  { id: "id", label: "Sno", minWidth: 50 },
  { id: "title", label: "Title", minWidth: 100 },
  { id: "description", label: "Description", minWidth: 350 },
  { id: "code", label: "Code", minWidth: 100 },
  { id: "startDate", label: "Start Date", minWidth: 200 },
  { id: "endDate", label: "End Date", minWidth: 200 },
  {
    id: "discountPercentage",
    label: "Discount Percentage(%)",
    minWidth: 180,
  },
  { id: "flatvalue", label: "FLAT", minWidth: 120 },

  { id: "isActive", label: "IsActive", minWidth: 170 },

  { id: "ispreapplied", label: "Is PreApplied", minWidth: 120 },
  // { id: "offerimageurl", label: "Aadhaar", minWidth: 120 },
  { id: "action", label: "Action", minWidth: 100 },
];

const Coupons = () => {
  const [page, setPage] = React.useState(0);
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [popupOpen, setPopupOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [addCouponOpen, setAddCouponOpen] = useState(false);
  const [newCoupon, setNewCoupon] = useState({
    title: "",
    description: "",
    code: "",
    startDate: "",
    endDate: "",
    discountType: "percentage",
    discountValue: "",
    isActive: false,
  });
  const [errors, setErrors] = useState({});

  const getAllCoupons = async () => {
    let usersData = await BookingServices.getAllCoupons();
    if (usersData.status == true) {
      usersData.result.forEach((element) => {
        const originalStartDate = new Date(element.startDate);
        element.startDate = new Date(element.startDate).toLocaleString();

        //const originalEndDate = new Date(element.endDate);

        element.endDate = new Date(element.endDate).toLocaleString();

        if (element.isActive == true) {
          element.isActive = "Active";
        } else {
          element.isActive = "Not Active";
        }

        if (element.ispreapplied == true) {
          element.ispreapplied = "YES";
        } else {
          element.ispreapplied = "NO";
        }

        element.action = (
          <MoreVertIcon onClick={(event) => handleClick(event, element)} />
        );
      });
      setRows(usersData.result);
    }
  };

  useEffect(() => {
    getAllCoupons();
  }, []);

  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleBlockUser = async (id) => {
    const confirm = window.confirm("Are you sure you want to block this user?");
    if (confirm) {
      // const response = await UserServices.blockOrUnBlockUser(id, 0);
      // if (response.status == true) {
      //   alert("User Blocked Successfully");
      //   getAllCoupons();
      // } else {
      //   alert("Failed to block user");
      // }
    }
    setSelectedRow(null);
  };

  const handleMenuItemClick = async (action) => {
    if (selectedRow) {
      console.log("Selected Row" + selectedRow);
      console.log("Action" + action);
      if (action == "DeActivate") {
        console.log("Clicked Deactiavte Offer" + selectedRow.id);

        const data = await BookingServices.deActiveCoupon(selectedRow.id);
        if (data.status == true) {
          alert("Offer Deactivated Successfully");
        } else {
          alert("Failed to Deactivate Offer");
        }
        getAllCoupons();

        // UserServices.generateMobileOtp(selectedRow.id);
      } else if (action == "SendViaWhatsApp") {
        // UserServices.generateEmailOtp(selectedRow.id);
      } else if (action == "BlockUser") {
        handleBlockUser(selectedRow.id);
      }
    }
    handleClose();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredRows = rows.filter((row) =>
    columns.some((column) =>
      row[column.id]
        ?.toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    )
  );

  const renderProfileImage = (url, name) => {
    if (url) {
      return (
        <img
          src={url}
          alt="Profile"
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            objectFit: "cover",
          }}
        />
      );
    } else {
      return (
        <div
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            backgroundColor: "#ccc",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "20px",
            color: "#fff",
          }}
        >
          {name ? name.charAt(0).toUpperCase() : ""}
        </div>
      );
    }
  };

  const handleLinkClick = (type, id) => {
    console.log("Clicked" + type + "ID:" + id);
    const apiUrl = `http://10.2.2.144:3000/api/v1/get${type}Image/${id}`;
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        setImageUrl(data.imageUrl);
        setPopupOpen(true);
      });
  };

  const handlePopupClose = () => {
    setPopupOpen(false);
    setImageUrl("");
  };

  const handleAddCouponOpen = () => {
    setAddCouponOpen(true);
  };

  const handleAddCouponClose = () => {
    setAddCouponOpen(false);
  };

  const handleNewCouponChange = (e) => {
    const { name, value, type, checked } = e.target;
    setNewCoupon((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleAddCouponSubmit = async () => {
    const newErrors = {};
    if (!newCoupon.title) newErrors.title = "Title is required";
    if (!newCoupon.description)
      newErrors.description = "Description is required";
    if (!newCoupon.code) {
      newErrors.code = "Coupon code is required";
    } else if (!/^[a-z0-9]+$/i.test(newCoupon.code)) {
      newErrors.code = "Coupon code must be alphanumeric";
    }
    if (!newCoupon.startDate) newErrors.startDate = "Start date is required";
    if (!newCoupon.endDate) newErrors.endDate = "Expiry date is required";
    if (!newCoupon.discountValue)
      newErrors.discountValue = "Discount value is required";

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const formatDate = (dateStr) => {
      const date = new Date(dateStr);
      const yyyy = date.getFullYear();
      const mm = String(date.getMonth() + 1).padStart(2, "0");
      const dd = String(date.getDate()).padStart(2, "0");
      const hh = String(date.getHours()).padStart(2, "0");
      const mi = String(date.getMinutes()).padStart(2, "0");
      const ss = String(date.getSeconds()).padStart(2, "0");
      return `${yyyy}-${mm}-${dd} ${hh}:${mi}:${ss}`;
    };

    const formattedCoupon = {
      ...newCoupon,
      title: newCoupon.title,
      description: newCoupon.description,
      code: newCoupon.code.toUpperCase(),
      startdate: formatDate(newCoupon.startDate),
      enddate: formatDate(newCoupon.endDate),
      discountpercentage:
        newCoupon.discountType === "percentage" ? newCoupon.discountValue : 0,
      flatvalue:
        newCoupon.discountType === "flat" ? newCoupon.discountValue : 0,
    };

    console.log(formattedCoupon);

    let usersData = await BookingServices.AddNewCoupons(formattedCoupon);
    if (usersData.status == true) {
      alert("Coupon Added Successfully");
      getAllCoupons();
    } else {
      alert("Failed to add coupon");
    }

    handleAddCouponClose();
  };

  return (
    <Paper
      sx={{
        width: "100%",
        overflow: "hidden",
        padding: 2,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6" id="tableTitle" component="div">
          All Offers
        </Typography>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddCouponOpen}
            style={{ marginRight: "10px" }}
          >
            Add Coupon
          </Button>
          <TextField
            id="standard-basic"
            label="Search"
            variant="filled"
            value={searchTerm}
            onChange={handleSearchChange}
            sx={{ height: "50px" }} // Decrease the height of the TextField
            InputProps={{ style: { height: "50px" } }} // Ensure the input field height is also decreased
          />
        </div>
      </div>

      <Paper sx={{ width: "100%", overflow: "hidden", padding: 1 }}>
        <TableContainer sx={{ maxHeight: 460 }}>
          <Table size="small" stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      backgroundColor: "#f4f4f4",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredRows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.id === "profile" ? (
                              renderProfileImage(row.profilePicture, row.name)
                            ) : column.id === "aadhaar" ? (
                              row.aadharurl ? (
                                <a
                                  href={row.aadharurl}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {value}
                                </a>
                              ) : (
                                value
                              )
                            ) : column.id === "dl" ? (
                              row.dlurl ? (
                                <a
                                  href={row.dlurl}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {value}
                                </a>
                              ) : (
                                value
                              )
                            ) : column.format && typeof value === "number" ? (
                              column.format(value)
                            ) : (
                              value
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={filteredRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={() => handleMenuItemClick("DeActivate")}>
          Deactivate
        </MenuItem>
      </Menu>

      <Dialog open={popupOpen} onClose={handlePopupClose}>
        <DialogTitle>Image</DialogTitle>
        <DialogContent>
          <img src={imageUrl} alt="Document" style={{ width: "100%" }} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePopupClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={addCouponOpen} onClose={handleAddCouponClose}>
        <DialogTitle>Add Coupon</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Title"
            type="text"
            fullWidth
            name="title"
            value={newCoupon.title}
            onChange={handleNewCouponChange}
            error={!!errors.title}
            helperText={errors.title}
          />
          <TextField
            margin="dense"
            label="Description"
            type="text"
            fullWidth
            name="description"
            value={newCoupon.description}
            onChange={handleNewCouponChange}
            error={!!errors.description}
            helperText={errors.description}
          />
          <TextField
            margin="dense"
            label="Coupon Code"
            type="text"
            fullWidth
            name="code"
            value={newCoupon.code}
            onChange={handleNewCouponChange}
            error={!!errors.code}
            helperText={errors.code}
          />
          <TextField
            margin="dense"
            label="Start Date"
            type="datetime-local"
            fullWidth
            name="startDate"
            value={newCoupon.startDate}
            onChange={handleNewCouponChange}
            InputLabelProps={{
              shrink: true,
            }}
            error={!!errors.startDate}
            helperText={errors.startDate}
          />
          <TextField
            margin="dense"
            label="Expiry Date"
            type="datetime-local"
            fullWidth
            name="endDate"
            value={newCoupon.endDate}
            onChange={handleNewCouponChange}
            InputLabelProps={{
              shrink: true,
            }}
            error={!!errors.endDate}
            helperText={errors.endDate}
          />
          <FormControl component="fieldset" margin="dense">
            <FormLabel component="legend">Discount Type</FormLabel>
            <RadioGroup
              row
              name="discountType"
              value={newCoupon.discountType}
              onChange={handleNewCouponChange}
            >
              <FormControlLabel
                value="percentage"
                control={<Radio />}
                label="Percentage"
              />
              <FormControlLabel value="flat" control={<Radio />} label="FLAT" />
            </RadioGroup>
          </FormControl>
          <TextField
            margin="dense"
            label={
              newCoupon.discountType === "percentage"
                ? "Discount Percentage"
                : "Discount Value"
            }
            type="number"
            fullWidth
            name="discountValue"
            value={newCoupon.discountValue}
            onChange={handleNewCouponChange}
            error={!!errors.discountValue}
            helperText={errors.discountValue}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={newCoupon.isActive}
                onChange={handleNewCouponChange}
                name="isActive"
              />
            }
            label="Is Active"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddCouponClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddCouponSubmit} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default Coupons;
