import IconButton from "@mui/material/IconButton";
import React from "react";
import { AppBar, Toolbar, Typography } from "@mui/material";
// import { BrowserRouter as Router } from "react-router-dom";

import { Outlet } from "react-router-dom";

import MenuIcon from "@mui/icons-material/Menu";
import Divider from "@mui/material/Divider";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";

import NotificationsIcon from "@mui/icons-material/Notifications";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Collapse } from "@mui/material";
import NotificationComponent from "./pages/components/NotificationComponent";

import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";

import menuItems from "./pages/components/MenuListItems";
import AuthServices from "./services/AuthServices";
const Layout = () => {
  // const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const navigate = useNavigate();
  const settings = ["Profile", "Settings", "Logout"];

  const handleLogout = async () => {
    // Call API here
    console.log("Logout clicked");
    const confirmLogout = window.confirm("Are you sure to Logout");
    if (confirmLogout) {
      let data = await AuthServices.logout();
      // Example:
      if (data.status == true) {
        navigate("/login");
      } else {
        console.log("error", data);
      }
    }
  };
  //Add HandleProfile and HandleSettings
  const handleProfile = () => {
    console.log("Profile clicked");
    navigate("/profile");
  };
  const handleSettings = () => {
    console.log("Settings clicked");
    navigate("/settings");
  };

  const userProfile = {
    name: "D Kiran Kumar",
    mobile: "+91 8008575757",
    profilePicture:
      "https://objectstorage.ap-hyderabad-1.oraclecloud.com/n/axhsu9endsms/b/testbharatcars/o/defaultimages%2Fuserimages%harrierfront.png", // Replace with actual URL
    isVerified: true,
  };

  const handleListItemClick = (path) => {
    navigate(path);
    handleDrawerClose();
  };

  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [openSubMenu, setOpenSubMenu] = React.useState(null);

  const handleSubMenuClick = (index) => {
    if (openSubMenu === index) {
      setOpenSubMenu(null);
    } else {
      setOpenSubMenu(index);
    }
  };

  const handleSubMenuItemClick = (path) => {
    navigate(path);
    handleDrawerClose();
  };

  return (
    <div>
      <NotificationComponent />
      <AppBar position="static">
        <Toolbar>
          <IconButton onClick={handleDrawerOpen}>
            <MenuIcon style={{ color: "white" }} />
          </IconButton>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            Bharat Self Drive Cars
          </Typography>

          <IconButton>
            <Badge
              badgeContent={0}
              color="secondary"
              style={{ color: "white", marginRight: "20px" }}
            >
              <NotificationsIcon style={{ color: "white" }} />
            </Badge>
          </IconButton>
          <Box sx={{ flexGrow: 0, marginLeft: "auto" }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="" src="" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem
                  key={setting}
                  onClick={() => {
                    handleCloseUserMenu();
                    if (setting === "Profile") {
                      handleProfile();
                    } else if (setting === "Settings") {
                      handleSettings();
                    } else if (setting === "Logout") {
                      handleLogout();
                    }
                  }}
                >
                  <Typography sx={{ textAlign: "center" }}>
                    {setting}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>

      <Drawer variant="temporary" open={open} onClose={handleDrawerClose}>
        <Box sx={{ display: "flex", alignItems: "center", p: 2 }}>
          {/* User profile section (consider a separate component) */}
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            variant="dot"
            color={userProfile.isVerified ? "success" : "error"}
          >
            <Avatar alt={userProfile.name} src={userProfile.profilePicture} />
          </Badge>
          <Box sx={{ ml: 2 }}>
            <Typography variant="h6">{userProfile.name}</Typography>
            <Typography variant="body2">{userProfile.mobile}</Typography>
            <Typography
              variant="body2"
              color={userProfile.isVerified ? "green" : "red"}
            >
              {userProfile.isVerified
                ? "Verified Account"
                : "Account Not Verified"}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <List>
          {menuItems.map((item, index) => (
            <div key={index}>
              <ListItem
                button={true.toString()}
                onClick={() =>
                  item.subMenuItems.length > 0
                    ? handleSubMenuClick(index)
                    : handleListItemClick(item.path)
                }
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
                {item.subMenuItems.length > 0 ? (
                  openSubMenu === index ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )
                ) : null}
              </ListItem>
              {item.subMenuItems.length > 0 ? (
                <Collapse
                  in={openSubMenu === index}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    {item.subMenuItems.map((subItem, subIndex) => (
                      <ListItem
                        button={true}
                        key={subIndex}
                        onClick={() => handleSubMenuItemClick(subItem.path)}
                        sx={{ pl: 4 }}
                      >
                        <ListItemIcon>{subItem.icon}</ListItemIcon>
                        <ListItemText primary={subItem.text} />
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              ) : null}
            </div>
          ))}
        </List>
      </Drawer>
      <Box
        sx={{
          minHeight: "100vh",
          mt: 1,
        }}
      >
        <Outlet />
      </Box>
    </div>
  );
};

export default Layout;
