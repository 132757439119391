import CookiesServices from "./CookiesServices";

import HttpService from "./HttpService";
import axios from "axios";
import { apiEndpoint } from "../configuration";

let axiosConfig = {
  withCredentials: true,
};

class DashboardServices {
  constructor() {
    const token = CookiesServices.get("token");
    token ? (this.authenticated = true) : (this.authenticated = false);
    this.http = new HttpService();
  }

  async getDashboardOverview() {
    try {
      const response = await axios.get(
        `${apiEndpoint}/dashboard/overview`,
        {},
        axiosConfig
      );
      return response.data;
    } catch (error) {
      console.error("error", error);
      return false;
    }
  }
  async getTodayNotifications() {
    try {
      const response = await axios.get(
        `${apiEndpoint}/notifications/web`,
        {},
        axiosConfig
      );
      return response.data;
    } catch (error) {
      console.error("error", error);
      return false;
    }
  }
  async getTodaysBookings() {
    try {
      const response = await axios.post(
        `${apiEndpoint}/bookings/todaybookings`,
        {},
        axiosConfig
      );
      return response.data;
    } catch (error) {
      console.error("error", error);
      return false;
    }
  }

  async getDashboardBookingSummary() {
    try {
      const response = await axios.get(
        `${apiEndpoint}/dashboard/bookingsummary`,
        {},
        axiosConfig
      );
      return response.data;
    } catch (error) {
      console.error("error", error);
      return false;
    }
  }

  async getDashboardRevenueSummary() {
    try {
      const response = await axios.get(
        `${apiEndpoint}/dashboard/revenuesummary`,
        {},
        axiosConfig
      );
      return response.data;
    } catch (error) {
      console.error("error", error);
      return false;
    }
  }

  async getDashboardCustHostSummary() {
    try {
      const response = await axios.get(
        `${apiEndpoint}/dashboard/custhostSummary`,
        {},
        axiosConfig
      );
      return response.data;
    } catch (error) {
      console.error("error", error);
      return false;
    }
  }

  async getDashboardVehicleSummary() {
    try {
      const response = await axios.get(
        `${apiEndpoint}/dashboard/vehiclesummary`,
        {},
        axiosConfig
      );
      return response.data;
    } catch (error) {
      console.error("error", error);
      return false;
    }
  }
}
export default new DashboardServices();
