import {
  Typography,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import * as React from "react";
import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

import DownloadIcon from "@mui/icons-material/Download";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import BookingServices from "../../services/BookingServices";
const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
};
const columns = [
  { id: "id", label: "Sno", minWidth: 50 },
  { id: "bookingid", label: "Booking ID", minWidth: 150 },
  { id: "hostname", label: "Host Name", minWidth: 150 },
  { id: "customername", label: "Customer Name", minWidth: 150 },
  { id: "vehiclenumber", label: "Vehicle No", minWidth: 125 },
  { id: "vehiclemake", label: "Make", minWidth: 110 },
  {
    id: "vehiclemodel",
    label: "Model",
    minWidth: 100,
  },
  { id: "plannedstartdatetime", label: "Start Date time", minWidth: 200 },

  { id: "plannedenddatetime", label: "End Date time", minWidth: 200 },

  { id: "bookedhours", label: "Booked Hrs", minWidth: 120 },
  { id: "basefare", label: "Base Fare", minWidth: 100 },
  {
    id: "protectionplanamount",
    label: "Protection Plan",
    minWidth: 180,
  },
  { id: "platformcharges", label: "Platform Charges", minWidth: 150 },
  // { id: "profilePicture", label: "Profile Pic", minWidth: 100 },
  { id: "offercode", label: "Offer Code", minWidth: 100 },

  { id: "gst", label: "GST ", minWidth: 100 },
  { id: "total", label: "Total", minWidth: 150 },
  { id: "extrahrstravel", label: "Extra Hrs Travelled", minWidth: 180 },
  { id: "extrahrsvalue", label: "Extra Charges", minWidth: 150 },
  { id: "extragst", label: "Extra GST", minWidth: 150 },
  { id: "grandtotal", label: "Grand Total", minWidth: 150 },
  { id: "paymentstatus", label: "Payment Status", minWidth: 150 },
  { id: "bookingstatus", label: "Booking Status", minWidth: 150 },
  { id: "leaseagreementurl", label: "Lease Agreement", minWidth: 150 },

  { id: "action", label: "Action", minWidth: 100 },
];

const LiveBookings = () => {
  const [page, setPage] = React.useState(0);
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [popupOpen, setPopupOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [selectedOption, setSelectedOption] = useState("");

  const [dialogOpen, setDialogOpen] = useState(false);
  const [imageDialogOpen, setImageDialogOpen] = useState(false);
  const [dialogImages, setDialogImages] = useState([]);
  const [dialogTitle, setDialogTitle] = useState("");

  const [selectedTab, setSelectedTab] = useState(0);

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedOption("");
  };

  const getMenuItems = (bookingstatusid) => {
    console.log("Booking Status ID=>", bookingstatusid);
    switch (bookingstatusid) {
      case 2:
        return (
          <>
            <MenuItem onClick={() => handleMenuItemClick("SLA")}>
              Send Lease Agreement
            </MenuItem>
            <MenuItem onClick={() => handleMenuItemClick("CB")}>
              Cancel Booking
            </MenuItem>
          </>
        );
      case 6:
        return (
          <>
            <MenuItem onClick={() => handleMenuItemClick("SLA")}>
              Send Lease Agreement
            </MenuItem>
            <MenuItem onClick={() => handleMenuItemClick("CB")}>
              Cancel Booking
            </MenuItem>
            <MenuItem onClick={() => handleMenuItemClick("VPRCI")}>
              View PreCheck Images
            </MenuItem>
          </>
        );
      case 7:
        return (
          <>
            <MenuItem onClick={() => handleMenuItemClick("SLA")}>
              Send Lease Agreement
            </MenuItem>
            <MenuItem onClick={() => handleMenuItemClick("VPRCI")}>
              View PreCheck Images
            </MenuItem>
            <MenuItem onClick={() => handleMenuItemClick("AM")}>
              Assign Mechanic
            </MenuItem>
          </>
        );
      case 10:
        return (
          <>
            <MenuItem onClick={() => handleMenuItemClick("SLA")}>
              Send Lease Agreement
            </MenuItem>
            <MenuItem onClick={() => handleMenuItemClick("VPRCI")}>
              View PreCheck Images
            </MenuItem>
            <MenuItem onClick={() => handleMenuItemClick("VPOCI")}>
              View PostCheck Images
            </MenuItem>
          </>
        );
      case 11:
        return (
          <>
            <MenuItem onClick={() => handleMenuItemClick("SLA")}>
              Send Lease Agreement
            </MenuItem>
            <MenuItem onClick={() => handleMenuItemClick("VPRCI")}>
              View PreCheck Images
            </MenuItem>
            <MenuItem onClick={() => handleMenuItemClick("VPOCI")}>
              View PostCheck Images
            </MenuItem>
          </>
        );

      default:
        return null;
    }
  };

  useEffect(() => {
    getAllLiveBookings();
  }, []);

  const getAllLiveBookings = async () => {
    let bookingData = await BookingServices.getLiveBookings();
    console.log("Booking Data", bookingData);
    if (bookingData.status == true) {
      bookingData.result.forEach((element) => {
        element.plannedstartdatetime = new Date(
          element.plannedstartdatetime
        ).toLocaleString();
        element.plannedenddatetime = new Date(
          element.plannedenddatetime
        ).toLocaleString();
        element.leaseagreementurl = (
          <a href={element.leaseagreementurl} download>
            <DownloadIcon />
          </a>
        );
        element.action = (
          <MoreVertIcon onClick={(event) => handleClick(event, element)} />
        );
      });
      setRows(bookingData.result);
    }
  };

  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const clearSelectedRow = () => {
    setSelectedRow(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleGetPrePostCheckImages = async (selectedRow, type) => {
    console.log("Selected Row in GetPreCheckImages: " + selectedRow);
    if (selectedRow) {
      console.log(
        "Selected Row: in GetPreCheckImages> " + JSON.stringify(selectedRow)
      );
      try {
        const response = await BookingServices.getPrePostCheckImages(
          selectedRow.id,
          type
        );
        console.log("API Response>:", response);
        if (response.status == true) {
          console.log("Images:", response.result);
          setDialogImages(response.result);
          setDialogTitle(
            type +
              " Images - " +
              selectedRow.vehiclenumber +
              " - " +
              selectedRow.vehiclemake +
              " " +
              selectedRow.vehiclemodel +
              " - " +
              selectedRow.vehiclecolor
          );
          setImageDialogOpen(true);
        } else {
          console.log("Error Getting Images");
        }
      } catch (error) {
        console.error("Error Getting Images:", error);
      }
    } else {
      console.log("No row selected");
    }
    handleClose();
  };

  const handleOptionSelect = async (option, selectedRow) => {
    setDialogOpen(false);

    console.log("Selected Option: in HandleOptionSelect: " + option);

    console.log("Selected Rows in HandleOpen> " + JSON.stringify(selectedRow));
    if (selectedRow) {
      try {
        const response = await BookingServices.sendLeaseAgreement(
          selectedRow.id,
          option
        );
        console.log("API Response:", response);
        if (response.status == true) {
          alert("Lease Agreement Sent Successfully");
        } else {
          alert(response.message + "So, Not able to send agreement");
        }
      } catch (error) {
        console.error("Error sending lease agreement:", error);
      }
    } else {
      console.log("No row selected");
    }
    handleClose();
  };

  const handleMenuItemClick = (action) => {
    console.log("Selected Row on Menu Click:" + JSON.stringify(selectedRow));
    console.log("Action>" + action);

    if (action == "SLA") {
      console.log("sending Lease Agreement");
      setDialogOpen(true);
      setSelectedOption(selectedRow);
    }
    if (action == "VPRCI") {
      console.log("View PreCheck Images");
      handleGetPrePostCheckImages(selectedRow, "PreCheck");
    }
    if (action == "VPOCI") {
      console.log("View PostCheck Images");
      handleGetPrePostCheckImages(selectedRow, "PostCheck");
    }

    clearSelectedRow();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredRows = rows.filter((row) =>
    columns.some((column) =>
      row[column.id]
        ?.toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    )
  );

  const renderProfileImage = (url, name) => {
    if (url) {
      return (
        <img
          src={
            "https://objectstorage.ap-hyderabad-1.oraclecloud.com/p/hZPWPOckWcDMqlptYrGkeqlmBDOoJmJBkmjeTMAUqKhePWPP6pJFX_iKs2GCAQ1s/n/axhsu9endsms/b/testbharatcars/o/bcp-images" +
            url
          }
          alt="Profile"
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            objectFit: "cover",
          }}
        />
      );
    } else {
      return (
        <div
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            backgroundColor: "#ccc",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "20px",
            color: "#fff",
          }}
        >
          {name ? name.charAt(0).toUpperCase() : ""}
        </div>
      );
    }
  };

  const handleLinkClick = (type, id) => {
    console.log("Clicked" + type + "ID:" + id);
    const apiUrl = `http://10.2.2.144:3000/api/v1/get${type}Image/${id}`;
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        setImageUrl(data.imageUrl);
        setPopupOpen(true);
      });
  };

  const handlePopupClose = () => {
    setPopupOpen(false);
    setImageUrl("");
  };

  const handleImageDialogClose = () => {
    setImageDialogOpen(false);
    setDialogImages([]);
    setDialogTitle("");
  };

  return (
    <>
      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
          padding: 2,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" id="tableTitle" component="div">
            Live Bookings
          </Typography>
          <TextField
            id="standard-basic"
            label="Search"
            variant="filled"
            value={searchTerm}
            onChange={handleSearchChange}
            sx={{ height: "50px" }} // Decrease the height of the TextField
            slotProps={{ input: { style: { height: "50px" } } }} // Ensure the input field height is also decreased
          />
        </div>

        <Paper sx={{ width: "100%", overflow: "hidden", padding: 1 }}>
          <TableContainer sx={{ maxHeight: 460 }}>
            <Table size="small" stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        backgroundColor: "#f4f4f4",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredRows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                      >
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.id === "profile" ? (
                                renderProfileImage(row.profilePicture, row.name)
                              ) : column.id === "aadhaar" ||
                                column.id === "dl" ? (
                                <a
                                  href="#"
                                  onClick={() =>
                                    handleLinkClick(column.id, row.id)
                                  }
                                >
                                  {value}
                                </a>
                              ) : column.format && typeof value === "number" ? (
                                column.format(value)
                              ) : (
                                value
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={filteredRows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <div>{getMenuItems(selectedRow?.bookingstatusid)}</div>
        </Menu>

        <Dialog open={popupOpen} onClose={handlePopupClose}>
          <DialogTitle>Image</DialogTitle>
          <DialogContent>
            <img src={imageUrl} alt="Document" style={{ width: "100%" }} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handlePopupClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Select Recipient</DialogTitle>
        <DialogContent>
          <Button
            onClick={() => handleOptionSelect("Customer", selectedOption)}
          >
            Customer
          </Button>
          <Button onClick={() => handleOptionSelect("Host", selectedOption)}>
            Host
          </Button>
        </DialogContent>
      </Dialog>

      <Dialog
        open={imageDialogOpen}
        onClose={handleImageDialogClose}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <Tabs
            value={selectedTab}
            onChange={(_, newValue) => setSelectedTab(newValue)}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            {dialogImages.map((image, index) => (
              <Tab key={index} label={image.documenttypename} />
            ))}
          </Tabs>
          {dialogImages.map((image, index) => (
            <TabPanel key={index} value={selectedTab} index={index}>
              <div style={{ position: "relative", overflow: "hidden" }}>
                <img
                  src={image.url}
                  alt={image.documenttypename}
                  style={{
                    width: "100%",
                    transform: `scale(${image.scale || 1})`,
                    transformOrigin: "top left",
                  }}
                />
                <div style={{ position: "absolute", top: 10, right: 10 }}>
                  <Button
                    onClick={() => {
                      const newImages = [...dialogImages];
                      newImages[index].scale =
                        (newImages[index].scale || 1) + 0.1;
                      setDialogImages(newImages);
                    }}
                    style={{ backgroundColor: "#f0f0f0" }}
                  >
                    Zoom In
                  </Button>
                  <Button
                    onClick={() => {
                      const newImages = [...dialogImages];
                      newImages[index].scale =
                        (newImages[index].scale || 1) - 0.1;
                      setDialogImages(newImages);
                    }}
                    style={{ backgroundColor: "#f0f0f0" }}
                  >
                    Zoom Out
                  </Button>
                </div>
              </div>
            </TabPanel>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleImageDialogClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default LiveBookings;
