import { Typography, Menu, MenuItem } from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import UserServices from "../../services/UserServices";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const columns = [
  { id: "Sno", label: "Sno", minWidth: 50 },

  { id: "mobileno", label: "Mobile", minWidth: 150 },
  { id: "mobileOtp", label: "Mobile Otp", minWidth: 170 },
  {
    id: "mobileOtpGeneratedDatetime",
    label: "Generated DateTime",
    minWidth: 170,
  },
  { id: "expired", label: "Expired", minWidth: 100 },
  { id: "usertype", label: "User Type", minWidth: 100 },
  { id: "action", label: "Action", minWidth: 100 },
];

const PreRegisteredUsers = () => {
  const [page, setPage] = React.useState(0);
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const generateOTP = async (mobile, sendvia) => {
    let response = await UserServices.generateOTP(mobile, sendvia);
    console.log("Response: " + JSON.stringify(response));
    return response;
  };

  const getAllPreRegisteredUsers = async () => {
    let usersData = await UserServices.getAllPregisteredUsers();
    if (usersData.status == true) {
      let Sno = 0;
      usersData.result.forEach((element) => {
        element.Sno = Sno + 1;
        Sno = Sno + 1;
        //console.log("Mobile: " + element.mobile);
        element.mobile = element.mobile;
        element.mobileno = element.mobile;
        // element.mobileno = element.mobileno.replace(
        //   /^(\d{2})\d{5}(\d{3})$/,
        //   "$1*****$2"
        // );
        //console.log("Mobile 2: " + element.mobile);
        const originalDate = new Date(element.mobileOtpGeneratedDatetime);
        element.mobileOtpGeneratedDatetime = originalDate.toLocaleString();

        let newDate = originalDate.getTime() + 5 * 60 * 1000;

        let currentDate = new Date().getTime();

        if (newDate > currentDate) {
          element.expired = "Valid";
        } else {
          element.expired = "Expired";
        }
        if (element.userTypeid === 1) {
          element.usertype = "Customer";
        }
        if (element.userTypeid === 2) {
          element.usertype = "Host";
        }
        element.action = (
          <MoreVertIcon onClick={(event) => handleClick(event, element)} />
        );
      });
      setRows(usersData.result);
    }
  };

  useEffect(() => {
    getAllPreRegisteredUsers();
    // fetch("http://10.2.2.144:3000/api/v1/preregistrations")
    //   .then((response) => response.json())
    //   .then((data) => {
    //     data.result.forEach((element) => {
    //       element.mobile = element.mobile.replace(
    //         /^(\d{2})\d{5}(\d{3})$/,
    //         "$1*****$2"
    //       );
    //       element.mobileOtpGeneratedDatetime = new Date(
    //         element.mobileOtpGeneratedDatetime
    //       ).toLocaleString();
    //       if (
    //         new Date(element.mobileOtpGeneratedDatetime).getTime() +
    //           5 * 60 * 1000 >
    //           new Date().getTime() ||
    //         new Date(element.mobileOtpGeneratedDatetime).getTime() >
    //           new Date().getTime()
    //       ) {
    //         element.expired = "Valid";
    //       } else {
    //         element.expired = "Expired";
    //       }
    //       if (element.userTypeid === 1) {
    //         element.usertype = "Customer";
    //       }
    //       if (element.userTypeid === 2) {
    //         element.usertype = "Host";
    //       }
    //       element.action = (
    //         <MoreVertIcon onClick={(event) => handleClick(event, element)} />
    //       );
    //     });
    //     setRows(data.result);
    //   });
  }, []);

  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleMenuItemClick = async (action) => {
    if (selectedRow) {
      console.log("Selected Row" + JSON.stringify(selectedRow));
      console.log("Selected ID: " + selectedRow.id);
      console.log("Selected Mobile: " + selectedRow.mobile);
      console.log("Action: " + action);
      if (action === "GenerateOtp") {
        console.log("Generate OTP");
        const result = await generateOTP(selectedRow.mobile, 1);
        console.log("Result: " + JSON.stringify(result));
        if (result.status == true) {
          alert("OTP Generated Successfully");
        }
        await getAllPreRegisteredUsers();
      }
      // if (action === "SendViaWhatsApp") {
      //   console.log("Send Via WhatsApp");
      //   generateOTP(selectedRow.mobile, 2);
      //   getAllPreRegisteredUsers();
      // }

      // generateOTP(selectedRow.mobile, action);
      // const apiUrl = `http://10.2.2.144:3000/api/v1/${action.toLowerCase()}`;
      // fetch(apiUrl, {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      //   body: JSON.stringify({ mobile: selectedRow.mobile }),
      // })
      //   .then((response) => response.json())
      //   .then((data) => {
      //     console.log(data);
      //     handleClose();
      //   });
    }
    handleClose();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredRows = rows.filter((row) =>
    row.mobile.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Paper
      sx={{
        width: "100%",
        overflow: "hidden",
        padding: 2,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6" id="tableTitle" component="div">
          Pre-Registered Users
        </Typography>
        <TextField
          id="standard-basic"
          label="Search Mobile Number"
          variant="filled"
          value={searchTerm}
          onChange={handleSearchChange}
          sx={{ height: "50px" }} // Decrease the height of the TextField
          InputProps={{ style: { height: "50px" } }} // Ensure the input field height is also decreased
        />
      </div>

      <Paper sx={{ width: "100%", overflow: "hidden", padding: 1 }}>
        <TableContainer sx={{ maxHeight: 460 }}>
          <Table size="small" stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      backgroundColor: "#f4f4f4",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredRows.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={columns.length} align="center">
                    No Pre-Registerd Users Available
                  </TableCell>
                </TableRow>
              ) : (
                filteredRows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                      >
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format && typeof value === "number"
                                ? column.format(value)
                                : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={filteredRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={() => handleMenuItemClick("GenerateOtp")}>
          Re-Generate Otp
        </MenuItem>
      </Menu>
    </Paper>
  );
};

export default PreRegisteredUsers;
