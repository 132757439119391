import * as React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { TextField } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useLocation } from "react-router-dom";
import VerifiedIcon from "@mui/icons-material/Verified";
import Chip from "@mui/material/Chip";
import UnpublishedIcon from "@mui/icons-material/Unpublished";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import UserServices from "../../services/UserServices";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import VehicleServices from "../../services/VehicleServices";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { NewReleases } from "@mui/icons-material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Autocomplete from "@mui/material/Autocomplete";
import Slider from "@mui/material/Slider";

import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";

const ApprovalPage = () => {
  const location = useLocation();
  const [imageUrl, setImageUrl] = useState("");
  const [hostInfo, setHostInfo] = useState({});
  const [profileurl, setProfileUrl] = useState("");
  const [isAadharVerified, setIsAadharVerified] = useState(false);
  const [isDLVerified, setIsDLVerified] = useState(false);
  const [isMobileVerified, setIsMobileVerified] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [vehicleImages, setVehicleImages] = useState([]);
  const [vehiclePricing, setVehiclePricing] = useState([]);
  const [vehicleFeatures, setVehicleFeatures] = useState([]);
  const [vehicleVerification, setVehicleVerification] = useState([]);
  const [messageOfApi, setMessageOfApi] = useState("");
  const [vehicleDetails, setVehicleDetails] = useState({});
  const [aadharURL, setAadharURL] = useState("");
  const [dlURL, setDlURL] = useState("");

  const { selectedRow } = location.state;
  const [open, setOpen] = useState(false);
  const [allFieldsVerified, setAllFieldsVerified] = useState(false);
  const [dontShowImageApproveButton, setDontShowImageApproveButton] =
    useState(false);
  const [dontShowHostApproveButton, setDontShowHostApproveButton] =
    useState(false);

  const [dontShowFeaturesApproveButton, setDontShowFeaturesApproveButton] =
    useState(false);
  const [dontShowPriceApproveButton, setDontShowPriceApproveButton] =
    useState(false);

  const [descriptionDialogOpen, setDescriptionDialogOpen] = useState(false);
  const [newDescription, setNewDescription] = useState("");
  const [imageDialogOpen, setImageDialogOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageTitle, setImageTitle] = useState("");

  const [hostDialogOpen, setHostDialogOpen] = useState(false);
  const [aadharImage, setAadharImage] = useState("");
  const [dlImage, setDlImage] = useState("");

  const [pricingDialogOpen, setPricingDialogOpen] = useState(false);
  const [ratePerHour, setRatePerHour] = useState("");
  const [extraRatePerHour, setExtraRatePerHour] = useState("");

  const [locationDialogOpen, setLocationDialogOpen] = useState(false);
  const [locationSearch, setLocationSearch] = useState("");
  const [locationOptions, setLocationOptions] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState({
    address: "",
    latitude: 0,
    longitude: 0,
  });
  const [mapCenter, setMapCenter] = useState({
    lat: 12.971599,
    lng: 77.594566,
  });
  const [center, setCenter] = useState({ lat: 12.971599, lng: 77.594566 });
  const [cityId, setCityId] = useState("");
  const [cityName, setCityName] = useState("");

  const [markerPosition, setMarkerPosition] = useState({
    lat: 12.971599,
    lng: 77.594566,
  });
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyC3kUoMj1YyXsp24PnMhC_KLHWzQmau8aw",
  });

  const containerStyle = {
    width: "100%",
    height: "500px",
  };

  const handleVehicleDescriptionAdd = () => {
    setDescriptionDialogOpen(true);
  };

  const handleDescriptionDialogClose = () => {
    setDescriptionDialogOpen(false);
  };

  const handleDescriptionSave = async () => {
    const response = await VehicleServices.updateVehicleDescription(
      selectedRow.id,
      newDescription
    );
    if (response.status === true) {
      setMessageOfApi("Vehicle Description Updated Successfully");
      handleClickOpen();
      handleVerifyVehicleDetails();
    }
    setDescriptionDialogOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  useEffect(() => {
    if (!open) {
      // Your code to execute after the dialog is closed
      console.log("Dialog closed");
      handleGetUserData();
      handleGetVehicelImages();
      handleVehiclePricing();
      handleVehicleFeatures();
      handleVerifyVehicleDetails();
      handleGetVehicleById();
    }
  }, [open]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleGetVehicleById = async () => {
    const vehicleDetails = await VehicleServices.getVehicleDetailsById(
      selectedRow.id
    );
    console.log("Vehicle Detials=>" + JSON.stringify(vehicleDetails));
    if (vehicleDetails.status == true) {
      console.log(
        "Vehicle Detials Result=>" + JSON.stringify(vehicleDetails.result[0])
      );
      setVehicleDetails(vehicleDetails.result[0]);
      console.log(
        "Vehicle latitude:=>",
        vehicleDetails.result[0].baselatitude + "Vehicle longitude:=>",
        vehicleDetails.result[0].baselongitude
      );
      console.log(
        "vehicle City=:>",
        vehicleDetails.result[0].basecityid + ":=>",
        vehicleDetails.result[0].basecity
      );
      setCenter({
        lat: vehicleDetails.result[0].baselatitude,
        lng: vehicleDetails.result[0].baselongitude,
      });
      setMarkerPosition({
        lat: vehicleDetails.result[0].baselatitude,
        lng: vehicleDetails.result[0].baselongitude,
      });
      setSelectedLocation({
        address: "",
        latitude: vehicleDetails.result[0].baselatitude,
        longitude: vehicleDetails.result[0].baselongitude,
      });
      setCityId(vehicleDetails.result[0].basecityid); // Set cityId based on vehicle details
      setCityName(vehicleDetails.result[0].basecity); // Set cityName based on vehicle details
    } else {
      alert("Something went wrong");
    }
  };

  const handleGetVehicelImages = async () => {
    const vehicleImages = await VehicleServices.getVehicleImages(
      selectedRow.id
    );
    if (vehicleImages.status == true) {
      setVehicleImages(vehicleImages.result);
      vehicleImages.result.map((image) => {
        console.log("Vehicle Image:=>", image.url);
        if (image.url == null) {
          setDontShowImageApproveButton(true);
        }
      });
    }
    console.log("vehicleImages:=>", vehicleImages);
  };
  const handleVehiclePricing = async () => {
    const vehiclePricingRow = await VehicleServices.getVehiclePricing(
      selectedRow.id
    );
    console.log("vehiclePricingRow :=>", JSON.stringify(vehiclePricingRow));
    if (vehiclePricingRow.status == true) {
      setSliderValue(
        vehiclePricingRow.result[0].defaultHostPricing.suggestedRate
      );
      setMinRate(vehiclePricingRow.result[0].defaultHostPricing.minRate);
      setMaxRate(vehiclePricingRow.result[0].defaultHostPricing.maxRate);
      setSuggestedRate(
        vehiclePricingRow.result[0].defaultHostPricing.suggestedRate
      );
      if (vehiclePricingRow.result[0].hostPricing.length > 0) {
        setVehiclePricing(vehiclePricingRow.result[0]);

        setRatePerHour(vehiclePricingRow.result[0].hostPricing[0].ratePerHour);
        setExtraRatePerHour(
          vehiclePricingRow.result[0].hostPricing[0].extraRatePerHour
        );
      } else {
        console.log("No Pricing Found");
        setVehiclePricing([]);
        setDontShowPriceApproveButton(true); // Change function call to state update
      }
    }
    console.log("Vehicle Pricing:=>", JSON.stringify(vehiclePricing));
  };
  const handleVehicleFeatures = async () => {
    const vehicleFeatures = await VehicleServices.getVehicleFeatures(
      selectedRow.id
    );
    console.log("vehicleFeatures:=>", vehicleFeatures);
    if (vehicleFeatures.status == true) {
      if (vehicleFeatures.result.length > 0)
        setVehicleFeatures(vehicleFeatures.result);
      else {
        console.log("No Features Found");
        setDontShowFeaturesApproveButton(true);
      }
    } else {
      console.log("No Features Found");
      setVehicleFeatures([]);
    }
  };

  const handleVerifyVehicleDetails = async () => {
    const vehicleVerification =
      await VehicleServices.getVehicleVerificationInfo(selectedRow.id);
    console.log("Vehicle Verification:" + JSON.stringify(vehicleVerification));
    if (vehicleVerification.status == true) {
      setVehicleVerification(vehicleVerification.result);
      //Generate a code to verify each element of result and find Fields with ending with Verified and count and display count

      //Check if all the fields are verified
      if (
        vehicleVerification.result.basicVerified &&
        vehicleVerification.result.hostVerified &&
        vehicleVerification.result.imagesVerified &&
        vehicleVerification.result.featuresVerified &&
        vehicleVerification.result.pricingVerified &&
        vehicleVerification.result.descriptionVerified &&
        vehicleVerification.result.locationVerified
      ) {
        console.log("All Fields Verified");
        setAllFieldsVerified(true);
      } else {
        console.log("All Fields Not Verified");
        setAllFieldsVerified(false);
      }
    }
  };

  const handleBasicReject = async () => {
    const vehicleVerification = await VehicleServices.updateVehicleVerification(
      selectedRow.id,
      "basic",
      0
    );
    console.log("vehicleVerification:=>", vehicleVerification);
    if (vehicleVerification.status == true) {
      //generate a code to show dilog box to display successfully updated message
      console.log("Vehicle Basic Info Rejected Successfully");
      //Display a dialog box to show the success message
      setMessageOfApi("Vehicle Basic Info Rejected Successfully");
      handleClickOpen();
    }
  };

  const handleBasicApprove = async () => {
    console.log("selectedRow.id: In Basic Approve =>", selectedRow.id);
    const vehicleVerification = await VehicleServices.updateVehicleVerification(
      selectedRow.id,
      "basic",
      1
    );
    console.log("vehicleVerification:=>", vehicleVerification);
    if (vehicleVerification.status == true) {
      //generate a code to show dilog box to display successfully updated message
      console.log("Vehicle Basic Info Approved Successfully");
      //Display a dialog box to show the success message
      setMessageOfApi("Vehicle Basic Info Approved Successfully");
      handleClickOpen();
    }
  };

  const handleHostReject = async () => {
    const vehicleVerification = await VehicleServices.updateVehicleVerification(
      selectedRow.id,
      "host",
      0
    );
    console.log("vehicleVerification:=>", vehicleVerification);
    if (vehicleVerification.status == true) {
      //generate a code to show dilog box to display successfully updated message
      console.log("Vehicle Host Rejected Successfully");
      //Display a dialog box to show the success message
      setMessageOfApi("Vehicle Host Rejected Successfully");
      handleClickOpen();
    }
  };
  const handleHostApprove = async () => {
    const vehicleVerification = await VehicleServices.updateVehicleVerification(
      selectedRow.id,
      "host",
      1
    );
    console.log("vehicleVerification:=>", vehicleVerification);
    if (vehicleVerification.status == true) {
      //generate a code to show dilog box to display successfully updated message
      console.log("Vehicle Host Approved Successfully");
      //Display a dialog box to show the success message
      setMessageOfApi("Vehicle Host Approved Successfully");
      handleClickOpen();
    }
  };

  const handleImagesReject = async () => {
    const vehicleVerification = await VehicleServices.updateVehicleVerification(
      selectedRow.id,
      "images",
      0
    );
    console.log("vehicleVerification:=>", vehicleVerification);
    if (vehicleVerification.status == true) {
      //generate a code to show dilog box to display successfully updated message
      console.log("Vehicle Images Rejected Successfully");
      //Display a dialog box to show the success message
      setMessageOfApi("Vehicle Images Rejected Successfully");
      handleClickOpen();
    }
  };
  const handleImagesApprove = async () => {
    const vehicleVerification = await VehicleServices.updateVehicleVerification(
      selectedRow.id,
      "images",
      1
    );
    console.log("vehicleVerification:=>", vehicleVerification);
    if (vehicleVerification.status == true) {
      //generate a code to show dilog box to display successfully updated message
      console.log("Vehicle Images Approved Successfully");
      //Display a dialog box to show the success message
      setMessageOfApi("Vehicle Images Approved Successfully");
      handleClickOpen();
    }
  };

  const handleFeaturesReject = async () => {
    const vehicleVerification = await VehicleServices.updateVehicleVerification(
      selectedRow.id,
      "features",
      0
    );
    console.log("vehicleVerification:=>", vehicleVerification);
    if (vehicleVerification.status == true) {
      //generate a code to show dilog box to display successfully updated message
      console.log("Vehicle Features Rejected Successfully");
      //Display a dialog box to show the success message
      setMessageOfApi("Vehicle Features Rejected Successfully");
      handleClickOpen();
    }
  };
  const handleFeaturesApprove = async () => {
    const vehicleVerification = await VehicleServices.updateVehicleVerification(
      selectedRow.id,
      "features",
      1
    );
    console.log("vehicleVerification:=>", vehicleVerification);
    if (vehicleVerification.status == true) {
      //generate a code to show dilog box to display successfully updated message
      console.log("Vehicle Features Approved Successfully");
      //Display a dialog box to show the success message
      setMessageOfApi("Vehicle Features Approved Successfully");
      handleClickOpen();
    }
  };

  const handlePricingReject = async () => {
    const vehicleVerification = await VehicleServices.updateVehicleVerification(
      selectedRow.id,
      "pricing",
      0
    );
    console.log("vehicleVerification:=>", vehicleVerification);
    if (vehicleVerification.status == true) {
      //generate a code to show dilog box to display successfully updated message
      console.log("Vehicle Pricing Rejected Successfully");
      //Display a dialog box to show the success message
      setMessageOfApi("Vehicle Pricing Rejected Successfully");
      handleClickOpen();
    }
  };
  const handlePricingApprove = async () => {
    const vehicleVerification = await VehicleServices.updateVehicleVerification(
      selectedRow.id,
      "pricing",
      1
    );
    console.log("vehicleVerification:=>", vehicleVerification);
    if (vehicleVerification.status == true) {
      //generate a code to show dilog box to display successfully updated message
      console.log("Vehicle Pricing Approved Successfully");
      //Display a dialog box to show the success message
      setMessageOfApi("Vehicle Pricing Approved Successfully");
      handleClickOpen();
    }
  };

  const handleVehicleDescriptionReject = async () => {
    const vehicleVerification = await VehicleServices.updateVehicleVerification(
      selectedRow.id,
      "description",
      0
    );
    console.log("vehicleVerification:=>", vehicleVerification);
    if (vehicleVerification.status == true) {
      //generate a code to show dilog box to display successfully updated message
      console.log("Vehicle Description Rejected Successfully");
      //Display a dialog box to show the success message
      setMessageOfApi("Vehicle Description Rejected Successfully");
      handleClickOpen();
    }
  };
  const handleVehicleDescriptionApprove = async () => {
    const vehicleVerification = await VehicleServices.updateVehicleVerification(
      selectedRow.id,
      "description",
      1
    );
    console.log("vehicleVerification:=>", vehicleVerification);
    if (vehicleVerification.status == true) {
      //generate a code to show dilog box to display successfully updated message
      console.log("Vehicle Description Approved Successfully");
      //Display a dialog box to show the success message
      setMessageOfApi("Vehicle Description Approved Successfully");
      handleClickOpen();
    }
  };

  const handleLocationReject = async () => {
    const vehicleVerification = await VehicleServices.updateVehicleVerification(
      selectedRow.id,
      "location",
      0
    );
    console.log("vehicleVerification:=>", vehicleVerification);
    if (vehicleVerification.status == true) {
      //generate a code to show dilog box to display successfully updated message
      console.log("Vehicle Location Rejected Successfully");
      //Display a dialog box to show the success message
      setMessageOfApi("Vehicle Location Rejected Successfully");
      handleClickOpen();
    }
  };
  const handleLocationApprove = async () => {
    const vehicleVerification = await VehicleServices.updateVehicleVerification(
      selectedRow.id,
      "location",
      1
    );
    console.log("vehicleVerification:=>", vehicleVerification);
    if (vehicleVerification.status == true) {
      //generate a code to show dilog box to display successfully updated message
      console.log("Vehicle Location Approved Successfully");
      //Display a dialog box to show the success message
      setMessageOfApi("Vehicle Location Approved Successfully");
      handleClickOpen();
    }
  };

  const formatRupee = (amount) => {
    const formattedNumber = amount.toLocaleString("en-IN");
    return `\u20B9${formattedNumber}`;
  };

  const handleGetUserData = async () => {
    console.log("selectedRow.hostid:=>", selectedRow.hostid);
    const userData = await UserServices.getUserById(selectedRow.hostid);
    console.log("userData:=>", userData);
    if (userData.status == true) {
      setHostInfo(userData.result);
      setProfileUrl(userData.result.profilePicture);
      setIsAadharVerified(userData.result.isAadharVerified);
      setIsDLVerified(userData.result.isDlVerified);
      setIsMobileVerified(userData.result.isMobileVerified);
      setIsEmailVerified(userData.result.isEmailVerified);

      if (
        !userData.result.isMobileVerified ||
        !userData.result.isAadharVerified
      ) {
        setDontShowHostApproveButton(true);
      }
    }
  };

  useEffect(() => {
    console.log("Received selectedRow:", selectedRow);
    if (selectedRow.category == "Hatchback") {
      setImageUrl(
        "https://objectstorage.ap-hyderabad-1.oraclecloud.com/n/axhsu9endsms/b/testbharatcars/o/category%2Fhatchback.png"
      );
    }
    if (selectedRow.category == "Sedan") {
      setImageUrl(
        "https://objectstorage.ap-hyderabad-1.oraclecloud.com/n/axhsu9endsms/b/testbharatcars/o/category%2Fsedan.png"
      );
    }
    if (selectedRow.category == "SUV") {
      setImageUrl(
        "https://objectstorage.ap-hyderabad-1.oraclecloud.com/n/axhsu9endsms/b/testbharatcars/o/category%2Fsuv.png"
      );
    }
    if (selectedRow.category == "MUV") {
      setImageUrl(
        "https://objectstorage.ap-hyderabad-1.oraclecloud.com/n/axhsu9endsms/b/testbharatcars/o/category%2Fmuv.png"
      );
    }
    console.log("selectedRow.profilePicture:=>" + selectedRow.profilePicture);

    handleGetUserData();
    handleGetVehicelImages();
    handleVehiclePricing();
    handleVehicleFeatures();
    handleVerifyVehicleDetails();
    handleGetVehicleById();
  }, [selectedRow]);
  const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const handleVehicleImagesAdd = () => {
    setImageDialogOpen(true);
  };

  const handleImageDialogClose = () => {
    setImageDialogOpen(false);
    setSelectedImage(null);
    setImageTitle("");
  };

  const handleImageEdit = (image) => {
    setSelectedImage(image);

    setImageTitle(image.documenttypename);
  };

  const handleImageUpload = async (event) => {
    // curl --location 'http://10.2.2.144:3000/api/v1/documents/vehicleuploadimages'  --header 'Authorization: ••••••'  --form 'uploadfile=@"/C:/Users/Kiran Kumar/Downloads/hyundaieontaillamps.jpg"'  --form 'id=""'  --form 'documenttypeid="26"'  --form 'vehicleid="12"'
    //generate code for above curl command using axios and try to upload the image
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("uploadfile", file);
      if (selectedImage.id != null) {
        formData.append("id", selectedImage.id);
      }
      formData.append("documenttypeid", selectedImage.documenttypeid);
      formData.append("vehicleid", selectedRow.id);

      const response = await VehicleServices.vehicleImageUpload(formData);
      console.log("Response:=>", response);

      if (response.status === true) {
        setMessageOfApi("Image Uploaded Successfully");
        handleClickOpen();
        handleGetVehicelImages();
        // handleImageDialogClose();
      } else {
        alert("Image Upload Failed");
      }
    }
  };

  const handleHostUpdate = () => {
    setHostDialogOpen(true);
    setAadharImage(hostInfo.aadharImage); // Assuming hostInfo contains aadharImage
    setDlImage(hostInfo.dlImage); // Assuming hostInfo contains dlImage
  };

  const handleHostDialogClose = () => {
    setHostDialogOpen(false);
  };

  const handleAadharApprove = async () => {
    //Below 1 is Doctype 1 is aadhar, 2 is dl
    const response = await UserServices.updateUserDoc(selectedRow.hostid, 1, 1);
    if (response.status === true) {
      setMessageOfApi("Aadhar Card Approved Successfully");
      handleClickOpen();
      handleGetUserData();
    }
  };

  const handleAadharReject = async () => {
    const response = await UserServices.updateUserDoc(selectedRow.hostid, 1, 0);
    if (response.status === true) {
      setMessageOfApi("Aadhar Card Rejected Successfully");
      handleClickOpen();
      handleGetUserData();
    }
  };

  const handleDlApprove = async () => {
    const response = await UserServices.updateUserDoc(selectedRow.hostid, 2, 1);
    if (response.status === true) {
      setMessageOfApi("Driving License Approved Successfully");
      handleClickOpen();
      handleGetUserData();
    }
  };

  const handleDlReject = async () => {
    const response = await UserServices.updateUserDoc(selectedRow.hostid, 2, 0);
    if (response.status === true) {
      setMessageOfApi("Driving License Rejected Successfully");
      handleClickOpen();
      handleGetUserData();
    }
  };

  const handlePriceUpdate = async () => {
    console.log("Price Update Clicked");
    setPricingDialogOpen(true);
    //handleVehiclePricing();
  };
  const handleLocationUpdate = () => {
    handleGetAllCities();

    setLocationDialogOpen(true);
    setMapCenter({
      lat: vehicleDetails.latitude,
      lng: vehicleDetails.longitude,
    });
  };

  const handleGetAllCities = async () => {
    const response = await VehicleServices.getAllCities();
    console.log("Cities:=>", response);
    if (response.status == true) {
      setCityList(response.result); // Ensure cityList is set to an array
    }
  };

  const handleLocationDialogClose = () => {
    setLocationDialogOpen(false);
  };

  const handleLocationSearchChange = async (event, value) => {
    setLocationSearch(value);
    if (value.length > 4) {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${value}&key=AIzaSyC3kUoMj1YyXsp24PnMhC_KLHWzQmau8aw`
      );
      setLocationOptions(response.data.predictions);
    }
  };

  const handleLocationSelect = async (event, value) => {
    if (value) {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/place/autocomplete/json?placeid=${value.place_id}&key=AIzaSyC3kUoMj1YyXsp24PnMhC_KLHWzQmau8aw`
      );
      const location = response.data.result.geometry.location;
      setSelectedLocation({
        address: response.data.result.formatted_address,
        latitude: location.lat,
        longitude: location.lng,
      });
      setMapCenter({ lat: location.lat, lng: location.lng });
      setMarkerPosition({ lat: location.lat, lng: location.lng });
    }
  };

  const handleMarkerDragEnd = (event) => {
    console.log("Marker Draggng");
    setMarkerPosition({ lat: event.latLng.lat(), lng: event.latLng.lng() });
    setSelectedLocation({
      ...selectedLocation,
      latitude: event.latLng.lat(),
      longitude: event.latLng.lng(),
    });
  };

  const handleLocationSave = async () => {
    console.log("Select CityId:=>", cityId);
    console.log("Select CityName:=>", cityName);
    const response = await VehicleServices.updateVehicleLocation({
      id: selectedRow.id,
      baselatitude: selectedLocation.latitude,
      baselongitude: selectedLocation.longitude,
    });
    if (response.status === true) {
      setMessageOfApi("Location Updated Successfully");
      handleClickOpen();
      handleGetVehicleById();
    }
    const cityResponse = await VehicleServices.updateVehicleCity({
      id: selectedRow.id,
      basecityid: cityId,
      basecity: cityName,
    });
    console.log("City Response:=>", cityResponse);
    if (cityResponse.status === true) {
      console.log("City Updated Successfully");
    }

    setLocationDialogOpen(false);
  };

  //generate code to create a method to pass latitude, longitude as input parameters and get location name using google API
  const handleGetLocationName = async (latitude, longitude) => {
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyC3kUoMj1YyXsp24PnMhC_KLHWzQmau8aw`
      );
      console.log(
        "Location Name:=>",
        response.data.results[0].formatted_address
      );
      return response.data.results[0].formatted_address;
    } catch (error) {
      console.error("Error fetching location name:", error);
      return "";
    }
  };

  const handlePricingDialogOpen = () => {
    setPricingDialogOpen(true);
  };

  const handlePricingDialogClose = () => {
    setPricingDialogOpen(false);
  };

  const handlePricingSave = async () => {
    if (vehiclePricing.length == 0) {
      console.log("No Pricing Set");
      console.log("Host Info" + JSON.stringify(hostInfo));
      console.log("Vehicle Info" + JSON.stringify(vehicleDetails));

      console.log("Selected Row:=>", selectedRow);
      console.log("extraRatePerHour:=>", extraRatePerHour);
      if (extraRatePerHour == "") {
        console.log("Extra Rate Per Hour is empty");
        setExtraRatePerHour(ratePerHour);
      }
      const response = await VehicleServices.addHostVehiclePricing({
        vehicleid: selectedRow.id,
        hostid: selectedRow.hostid,
        rateperhour: ratePerHour,
        extrarateperhour: extraRatePerHour,
        basehours: 10,
        day: "[0,1,2,3,4,5,6]",
      });

      if (response.status === true) {
        setMessageOfApi("Pricing Added Successfully");
        handleClickOpen();
        handleVehiclePricing();
      }
    } else {
      console.log("Pricing is set");

      console.log("id:=>", vehiclePricing.hostPricing[0].id);
      console.log("Rare Per Hour:=>", ratePerHour);
      console.log("Extra Rate Per Hour:=>", extraRatePerHour);

      const response = await VehicleServices.updateVehiclePricing({
        id: vehiclePricing.hostPricing[0].id,
        rateperhour: ratePerHour,
        extrarateperhour: extraRatePerHour,
        basehours: 10,
        day: "[0,1,2,3,4,5,6]",
      });
      if (response.status === true) {
        setMessageOfApi("Pricing Updated Successfully");
        handleClickOpen();
        handleVehiclePricing();
      }
    }
    setPricingDialogOpen(false);
  };
  const [sliderValue, setSliderValue] = useState(0);
  const [minRate, setMinRate] = useState(0);
  const [maxRate, setMaxRate] = useState(0);
  const [suggestedRate, setSuggestedRate] = useState(0);
  const handleSliderChange = (event, newValue) => {
    setSliderValue(newValue);
    setRatePerHour(newValue);
  };

  useEffect(() => {
    setSliderValue(suggestedRate);
  }, [suggestedRate]);

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading...</div>;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        gap: "16px",
      }}
    >
      <div style={{ flex: 1, textAlign: "left" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: 2,
          }}
        >
          <Typography
            variant="h6"
            id="tableTitle"
            component="div"
            sx={{ marginBottom: 2 }}
          >
            <strong>Approval of {selectedRow.vehicleno}</strong>
          </Typography>
          {allFieldsVerified ? (
            <Chip
              variant="outlined"
              color="success"
              label="Verified"
              icon={<VerifiedIcon />}
            />
          ) : (
            <Chip
              variant="outlined"
              color="warning"
              label="Not Verified"
              icon={<NewReleases />}
            />
          )}
        </div>

        <div style={{ display: "flex", gap: "16px", margin: 2 }}>
          <Card
            sx={{
              flex: 1,
              marginLeft: 2,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <CardMedia
              sx={{ height: 150 }} // Adjusted height
              image={imageUrl}
              title="Vehicle Image"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                {vehicleDetails.vehicleno}
              </Typography>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                {vehicleDetails.make} &#x2022; {vehicleDetails.model} &#x2022;{" "}
                {vehicleDetails.color}
                <br />
                {vehicleDetails.category} &#x2022; {vehicleDetails.fueltype}{" "}
                &#x2022; {vehicleDetails.transmission}
                <br />
                {vehicleDetails.vehicleseatingcapacity} Seater &#x2022;{" "}
                {vehicleDetails.registrationyear} Registered <br />
                {vehicleDetails.vehiclekms} Kms
              </Typography>
            </CardContent>
            <CardActions sx={{ justifyContent: "flex-end", marginTop: "auto" }}>
              {vehicleVerification.basicVerified ? (
                <Button size="small" onClick={handleBasicReject}>
                  Reject
                </Button>
              ) : (
                <Button size="small" onClick={handleBasicApprove}>
                  Approve
                </Button>
              )}
            </CardActions>
          </Card>

          <Card
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <CardMedia
              sx={{ height: 150 }} // Adjusted height
              image={profileurl}
              title="Host Profile Image"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                {hostInfo.name}
              </Typography>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  variant="body2"
                  sx={{ color: "text.secondary", textAlign: "left" }}
                >
                  {hostInfo.mobile}
                  <span style={{ color: "red" }}>*</span>
                </Typography>

                {isMobileVerified ? (
                  <VerifiedIcon
                    color="success"
                    sx={{
                      height: "20px",
                      textAlign: "right",
                      width: "20px",
                    }}
                  />
                ) : (
                  <NewReleasesIcon
                    color="warning"
                    sx={{
                      height: "20px",
                      textAlign: "right",
                      width: "20px",
                    }}
                  />
                )}
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  variant="body2"
                  sx={{ color: "text.secondary", textAlign: "left" }}
                >
                  {hostInfo.email}
                </Typography>
                {isEmailVerified ? (
                  <VerifiedIcon
                    color="success"
                    sx={{
                      height: "20px",
                      textAlign: "right",
                      width: "20px",
                    }}
                  />
                ) : (
                  <NewReleasesIcon
                    color="warning"
                    sx={{
                      height: "20px",
                      textAlign: "right",
                      width: "20px",
                    }}
                  />
                )}
              </div>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  variant="body2"
                  sx={{ color: "text.secondary", textAlign: "left" }}
                >
                  Aadhar Card <span style={{ color: "red" }}>*</span>
                </Typography>
                {isAadharVerified ? (
                  <VerifiedIcon
                    color="success"
                    sx={{
                      height: "20px",
                      textAlign: "right",
                      width: "20px",
                    }}
                  />
                ) : (
                  <NewReleasesIcon
                    color="warning"
                    sx={{
                      height: "20px",
                      textAlign: "right",
                      width: "20px",
                    }}
                  />
                )}
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  variant="body2"
                  sx={{ color: "text.secondary", textAlign: "left" }}
                >
                  Driving License
                </Typography>
                {isDLVerified ? (
                  <VerifiedIcon
                    color="success"
                    sx={{
                      height: "20px",
                      textAlign: "right",
                      width: "20px",
                    }}
                  />
                ) : (
                  <NewReleasesIcon
                    color="warning"
                    sx={{
                      height: "20px",
                      textAlign: "right",
                      width: "20px",
                    }}
                  />
                )}
              </div>
            </CardContent>

            <CardActions sx={{ justifyContent: "flex-end", marginTop: "auto" }}>
              <Button size="small" onClick={handleHostUpdate}>
                Update
              </Button>

              {vehicleVerification.hostVerified ? (
                <Button size="small" onClick={handleHostReject}>
                  Reject
                </Button>
              ) : !dontShowHostApproveButton ? (
                <Button size="small" onClick={handleHostApprove}>
                  Approve
                </Button>
              ) : null}
            </CardActions>
          </Card>

          <Card
            sx={{
              maxWidth: 345,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Carousel
              showArrows={true}
              autoPlay={true}
              infiniteLoop={true}
              showThumbs={false} // Removed Thumbs
            >
              {vehicleImages.map((image, index) => (
                <div key={index}>
                  {image.url ? (
                    <img
                      src={image.url}
                      alt={`Vehicle ${index + 1}`}
                      style={{
                        height: "260px",
                        width: "100%",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                          }}
                        >
                          <CloudUploadIcon
                            style={{ fontSize: "100px", color: "gray" }}
                          />
                        </div>
                      </div>
                      <br />

                      <div style={{ textAlign: "center" }}>
                        No Image Uploaded
                      </div>
                    </div>
                  )}

                  <Typography
                    gutterBottom
                    component="div"
                    sx={{ textAlign: "left", marginTop: 2, marginLeft: 2 }}
                  >
                    {image.documenttypename}
                  </Typography>
                </div>
              ))}
            </Carousel>
            {/* <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Pricing -{" "}
                {vehiclePricing && vehiclePricing.hostPricing
                  ? vehiclePricing.hostPricing[0].rateperhour + "/Hr"
                  : "Not Available"}
              </Typography>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  variant="body2"
                  sx={{ color: "text.secondary", textAlign: "left" }}
                >
                  Extra Hour Charges:{" "}
                  {vehiclePricing && vehiclePricing.hostPricing
                    ? vehiclePricing.hostPricing[0].extrarateperhour + "/Hr"
                    : "Not Available"}
                </Typography>
              </div>
              <Typography
                variant="body2"
                sx={{ color: "text.secondary", textAlign: "left" }}
              >
                Available On:{" "}
                {vehiclePricing && vehiclePricing.hostPricing
                  ? vehiclePricing.hostPricing[0].day
                  : "Not Available"}
              </Typography>
            </CardContent> */}

            <CardActions sx={{ justifyContent: "flex-end", marginTop: "auto" }}>
              <Button size="small" onClick={handleVehicleImagesAdd}>
                Update
              </Button>
              {vehicleVerification.imagesVerified ? (
                <Button size="small" onClick={handleImagesReject}>
                  Reject
                </Button>
              ) : !dontShowImageApproveButton ? (
                <Button size="small" onClick={handleImagesApprove}>
                  Approve
                </Button>
              ) : null}
            </CardActions>
          </Card>

          <Card
            sx={{
              maxWidth: 345,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Car Features
              </Typography>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(3, 1fr)",
                  gap: "8px",
                  maxHeight: "245px",
                  overflowY: "auto",
                }}
              >
                {vehicleFeatures.map((feature, index) => (
                  <div
                    key={index}
                    style={{
                      border: "1px solid #ccc",
                      borderRadius: "8px",
                      padding: "8px",
                      textAlign: "center",
                    }}
                  >
                    <img
                      src={feature.url}
                      alt={`Feature ${index + 1}`}
                      style={{
                        height: "32px", // Adjusted height
                        width: "32px",
                        objectFit: "cover",
                        borderRadius: "5px",
                      }}
                    />
                    <Typography
                      gutterBottom
                      component="div"
                      sx={{ marginTop: 1, fontSize: "0.875rem" }} // Reduced font size
                    >
                      {feature.feature}
                    </Typography>
                  </div>
                ))}
              </div>
            </CardContent>
            <CardActions sx={{ justifyContent: "flex-end", marginTop: "auto" }}>
              {vehicleVerification.featuresVerified ? (
                <Button size="small" onClick={handleFeaturesReject}>
                  Reject
                </Button>
              ) : !dontShowFeaturesApproveButton ? (
                <Button size="small" onClick={handleFeaturesApprove}>
                  Approve
                </Button>
              ) : null}
            </CardActions>
          </Card>
        </div>

        <div style={{ display: "flex", gap: "16px", margin: 2 }}>
          <Card
            sx={{
              flex: 1,
              marginLeft: 2,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Pricing Details
              </Typography>
              <Typography gutterBottom variant="h5" component="div">
                {vehiclePricing && vehiclePricing.hostPricing
                  ? formatRupee(vehiclePricing.hostPricing[0].rateperhour) +
                    "/Hr"
                  : "Not Available"}
              </Typography>

              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                Extra Hour Charges:{" "}
                {vehiclePricing && vehiclePricing.hostPricing
                  ? formatRupee(
                      vehiclePricing.hostPricing[0].extrarateperhour
                    ) + "/Hr"
                  : "Not Available"}
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: "text.secondary", textAlign: "left" }}
              >
                Available On:{" "}
                {vehiclePricing && vehiclePricing.hostPricing
                  ? vehiclePricing.hostPricing[0].day
                  : "Not Available"}
              </Typography>
              <div style={{ display: "flex", gap: "16px" }}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                ></div>
              </div>
            </CardContent>
            <CardActions sx={{ justifyContent: "flex-end", marginTop: "auto" }}>
              <Button size="small" onClick={handlePricingDialogOpen}>
                Update
              </Button>
              {vehiclePricing && vehiclePricing.hostPricing ? (
                vehicleVerification.pricingVerified ? (
                  <Button size="small" onClick={handlePricingReject}>
                    Reject
                  </Button>
                ) : !dontShowPriceApproveButton ? (
                  <Button size="small" onClick={handlePricingApprove}>
                    Approve
                  </Button>
                ) : null
              ) : null}
            </CardActions>
          </Card>

          <Card
            sx={{
              flex: 1,
              marginLeft: 2,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Base Location
              </Typography>
              <Typography gutterBottom variant="h5" component="div">
                {vehicleDetails.basecity}
              </Typography>

              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                {vehicleDetails.baselocality}
              </Typography>
            </CardContent>
            <CardActions sx={{ justifyContent: "flex-end", marginTop: "auto" }}>
              <Button size="small" onClick={handleLocationUpdate}>
                Update
              </Button>
              {vehicleVerification.locationVerified ? (
                <Button size="small" onClick={handleLocationReject}>
                  Reject
                </Button>
              ) : (
                <Button size="small" onClick={handleLocationApprove}>
                  Approve
                </Button>
              )}
            </CardActions>
          </Card>
          <Card
            sx={{
              flex: 1,
              marginLeft: 2,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Vehicle Description
              </Typography>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                {vehicleDetails.description}
              </Typography>
            </CardContent>

            <CardActions sx={{ justifyContent: "flex-end", marginTop: "auto" }}>
              <Button size="small" onClick={handleVehicleDescriptionAdd}>
                Update
              </Button>
              {vehicleVerification.descriptionVerified ? (
                <Button size="small" onClick={handleVehicleDescriptionReject}>
                  Reject
                </Button>
              ) : (
                <Button size="small" onClick={handleVehicleDescriptionApprove}>
                  Approve
                </Button>
              )}
            </CardActions>
          </Card>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Success"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {messageOfApi}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        maxWidth="md"
        fullWidth
        open={descriptionDialogOpen}
        onClose={handleDescriptionDialogClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Vehicle Description</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="description"
            label="Vehicle Description"
            type="text"
            fullWidth
            value={newDescription}
            onChange={(e) => setNewDescription(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDescriptionDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDescriptionSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        maxWidth="md"
        fullWidth
        open={imageDialogOpen}
        onClose={handleImageDialogClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Vehicle Images of {selectedRow.vehicleno}
        </DialogTitle>
        <DialogContent>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              gap: "16px",
            }}
          >
            {vehicleImages.map((image, index) => (
              <div key={index} style={{ position: "relative" }}>
                {image.url ? (
                  <img
                    src={image.url}
                    alt={`Vehicle ${index + 1}`}
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                        }}
                      >
                        <CloudUploadIcon
                          style={{ fontSize: "100px", color: "gray" }}
                        />
                      </div>
                    </div>
                    <div style={{ textAlign: "center" }}>No Image Uploaded</div>
                  </div>
                )}
                <Typography
                  variant="body2"
                  sx={{ textAlign: "center", marginTop: 1 }}
                >
                  {image.documenttypename}
                </Typography>

                <Button
                  size="small"
                  style={{ position: "absolute", top: 8, right: 8 }}
                  onClick={() => {
                    setSelectedImage(image);
                    document.getElementById(`fileInput-${index}`).click();
                  }}
                >
                  Edit
                </Button>
                <input
                  type="file"
                  id={`fileInput-${index}`}
                  style={{ display: "none" }}
                  onChange={handleImageUpload}
                />
              </div>
            ))}
          </div>
          {/* {selectedImage && (
            <div style={{ marginTop: "16px" }}>
              <Typography variant="h6">Edit Image</Typography>

              <input type="file" onChange={handleImageUpload} />
            </div>
          )} */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleImageDialogClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        maxWidth="md"
        fullWidth
        open={hostDialogOpen}
        onClose={handleHostDialogClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Host Verification</DialogTitle>
        <DialogContent>
          <div style={{ display: "flex", gap: "16px" }}>
            <div style={{ flex: 1, textAlign: "center" }}>
              <img
                src={hostInfo.aadharurl}
                alt="Aadhar Card"
                style={{ width: "100%", height: "200px", objectFit: "cover" }}
              />
              <Typography variant="body2" sx={{ marginTop: 1 }}>
                Aadhar Card : {hostInfo.aadhaar}
              </Typography>
              <Button size="small" onClick={handleAadharApprove}>
                Approve
              </Button>
              <Button size="small" onClick={handleAadharReject}>
                Reject
              </Button>
            </div>
            <div style={{ flex: 1, textAlign: "center" }}>
              <img
                src={hostInfo.dlurl}
                alt="Driving License"
                style={{ width: "100%", height: "200px", objectFit: "cover" }}
              />
              <Typography variant="body2" sx={{ marginTop: 1 }}>
                Driving License : {hostInfo.dl}
              </Typography>
              <Button size="small" onClick={handleDlApprove}>
                Approve
              </Button>
              <Button size="small" onClick={handleDlReject}>
                Reject
              </Button>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleHostDialogClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        maxWidth="md"
        fullWidth
        open={pricingDialogOpen}
        onClose={handlePricingDialogClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Update Pricing</DialogTitle>
        <DialogContent>
          Minimum Rate Per Hour: <b>{minRate}</b>
          &nbsp; &nbsp; &nbsp; &nbsp;Maximum Rate Per Hour: <b>{maxRate}</b>
          &nbsp; &nbsp; &nbsp; &nbsp; Suggested Rate Per Hour:{" "}
          <b>{suggestedRate}</b>
          <Slider
            value={sliderValue}
            min={minRate}
            max={maxRate}
            onChange={handleSliderChange}
            valueLabelDisplay="auto"
            sx={{
              "& .MuiSlider-track": {
                background: `linear-gradient(90deg, yellow ${
                  ((sliderValue - minRate) / (maxRate - minRate)) * 100
                }%, green 50%, red ${
                  ((maxRate - sliderValue) / (maxRate - minRate)) * 100
                }%)`,
              },
            }}
          />
          <TextField
            autoFocus
            margin="dense"
            id="ratePerHour"
            label="Rate Per Hour"
            type="number"
            fullWidth
            value={ratePerHour}
            onChange={(e) => setRatePerHour(e.target.value)}
          />
          <TextField
            margin="dense"
            id="extraRatePerHour"
            label="Extra Rate Per Hour"
            type="number"
            fullWidth
            value={extraRatePerHour}
            onChange={(e) => setExtraRatePerHour(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePricingDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handlePricingSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        maxWidth="md"
        fullWidth
        open={locationDialogOpen}
        onClose={handleLocationDialogClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Update Location</DialogTitle>
        <DialogContent>
          <Autocomplete
            freeSolo
            options={locationOptions}
            getOptionLabel={(option) => option.description}
            onInputChange={handleLocationSearchChange}
            onChange={handleLocationSelect}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search Location"
                variant="outlined"
                fullWidth
              />
            )}
          />
          <TextField
            select
            label="Select City"
            value={cityName} // Use cityName for the value
            onChange={(e) => {
              const selectedCity = cityList.find(
                (city) => city.name === e.target.value
              );
              if (selectedCity) {
                setCityId(selectedCity.id);
                setCityName(selectedCity.name);
                setSelectedLocation({
                  ...selectedLocation,
                  address: selectedCity.name,
                });
              }
            }}
            SelectProps={{
              native: true,
            }}
            fullWidth
            margin="normal"
          >
            {Array.isArray(cityList) &&
              cityList.map((city) => (
                <option key={city.id} value={city.name}>
                  {city.name}
                </option>
              ))}
          </TextField>
          <div style={{ height: "400px", width: "100%", marginTop: "16px" }}>
            {isLoaded && (
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={18}
              >
                <MarkerF
                  position={markerPosition}
                  draggable={true}
                  onDragEnd={handleMarkerDragEnd}
                  options={{
                    icon: {
                      url: "https://objectstorage.ap-hyderabad-1.oraclecloud.com/n/axhsu9endsms/b/testbharatcars/o/category%2Fhatchback.png", // Replace with your icon URL
                      scaledSize: new window.google.maps.Size(50, 50), // Set the size of the icon
                    },
                  }}
                />
              </GoogleMap>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          Base Latitude :{selectedLocation.latitude}, Base Longitude:
          {selectedLocation.longitude}
          <Button onClick={handleLocationDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleLocationSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ApprovalPage;
