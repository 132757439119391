import React from "react";
import { useEffect, useState } from "react";
import {
  Grid2,
  Card,
  CardContent,
  Typography,
  Box,
  ListItem,
  Divider,
  Container,
} from "@mui/material";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import RefreshIcon from "@mui/icons-material/Refresh";

import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import BookmarkAddIcon from "@mui/icons-material/BookmarkAdd";

import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import GroupIcon from "@mui/icons-material/Group";
import PersonIcon from "@mui/icons-material/Person";
import DashboardServices from "../../services/DashboardServices";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

// Remove toast.configure as it is not needed

const Dashboard = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [dashboarOverview, setDashboardOverview] = useState({});
  const [dashboardBookingSummary, setDashboardBookingSummary] = useState([]);
  const [dashboardRevenueSummary, setDashboardRevenueSummary] = useState([]);
  const [dashboardCustomerHostSummary, setDashboardCustomerHostSummary] =
    useState([]);
  const [dashboardVehicleSummary, setDashboardVehicleSummary] = useState([]);
  const [todayNotifications, setTodayNotifications] = useState([]);
  const [recentBookings, setRecentBookings] = useState([]);
  const getDashboardRevenueSummary = async () => {
    try {
      let revenueData = await DashboardServices.getDashboardRevenueSummary();
      console.log("DATA:" + JSON.stringify(revenueData));
      if (revenueData.status == true) {
        console.log("DATA:" + JSON.stringify(revenueData.result[0]));

        const revenuedata = [
          {
            count: revenueData.result[0].total,
            description: "Today's Revenue",
          },
          { count: revenueData.result[0].refund, description: "Refund" },
          { count: revenueData.result[0].payable, description: "Payable" },
          { count: revenueData.result[0].settled, description: "Settled" },
        ];

        setDashboardRevenueSummary(revenuedata);
      }
    } catch (error) {
      console.error("Error fetching revenue summary:", error);
    }
  };

  const getDashboardCustHostSummary = async () => {
    try {
      let custHostData = await DashboardServices.getDashboardCustHostSummary();
      console.log("DATA:" + JSON.stringify(custHostData));
      if (custHostData.status == true) {
        console.log("DATA:" + JSON.stringify(custHostData.result[0]));

        const customerhostdata = [
          {
            count: custHostData.result[0].preregistered,
            description: "Pre-Registered",
          },
          {
            count: custHostData.result[0].todaycustomer,
            description: "Today's Customers",
          },
          {
            count: custHostData.result[0].activecustomers,
            description: "Active Customers",
          },
          {
            count: custHostData.result[0].blockedcustomers,
            description: "Blocked Customers",
          },
          {
            count: custHostData.result[0].todayhost,
            description: "Today's Hosts",
          },
          {
            count: custHostData.result[0].activehosts,
            description: "Active Hosts",
          },
          {
            count: custHostData.result[0].blockedhosts,
            description: "Blocked Hosts",
          },
        ];
        setDashboardCustomerHostSummary(customerhostdata);
      }
    } catch (error) {
      console.error("Error fetching customer host summary:", error);
    }
  };

  const getDashboardVehicleSummary = async () => {
    try {
      let vehicleData = await DashboardServices.getDashboardVehicleSummary();
      console.log("DATA:" + JSON.stringify(vehicleData));
      if (vehicleData.status == true) {
        console.log("DATA:" + JSON.stringify(vehicleData.result[0]));
        //setDashboardVehicleSummary(vehicleData.result[0]);

        const vehicledata = [
          {
            count: vehicleData.result[0].approvalrequired,
            description: "Approval Required",
          },
          {
            count: vehicleData.result[0].activevehicles,
            description: "Active Vehicles",
          },
          {
            count: vehicleData.result[0].blockedvehicles,
            description: "Blocked Vehicles",
          },
          {
            count: vehicleData.result[0].nonlisted,
            description: "Non-Listing Vehicles",
          },
          {
            count: vehicleData.result[0].availablevehicles,
            description: "Availabe Vehicles",
          },
        ];
        setDashboardVehicleSummary(vehicledata);
      }
    } catch (error) {
      console.error("Error fetching vehicle summary:", error);
    }
  };

  const getDashboardOverview = async () => {
    try {
      let usersData = await DashboardServices.getDashboardOverview();
      console.log("DATA:" + JSON.stringify(usersData));
      if (usersData.status == true) {
        console.log("DATA:" + JSON.stringify(usersData.result[0]));
        setDashboardOverview(usersData.result[0]);
      }
    } catch (error) {
      console.error("Error fetching dashboard overview:", error);
    }
  };

  const getTodayNotifications = async () => {
    try {
      let notificationsData = await DashboardServices.getTodayNotifications();
      console.log("DATA:" + JSON.stringify(notificationsData));
      if (notificationsData.status == true) {
        console.log("DATA:" + JSON.stringify(notificationsData.result));

        setTodayNotifications(notificationsData.result);
      } else {
        console.log("There is not Notifications today");
        //SHow a toast message at the bottom of the screen close after 3 seconds
        toast.info("No notifications for today");
      }
    } catch (error) {
      console.error("Error fetching today notifications:", error);
    }
  };

  const getDashboadRecentBookings = async () => {
    try {
      let recentBookingsData = await DashboardServices.getTodaysBookings();
      console.log("DATA:" + JSON.stringify(recentBookingsData));
      if (recentBookingsData.status == true) {
        console.log(
          "Recent BookingsDATA:" + JSON.stringify(recentBookingsData.result)
        );
        setRecentBookings(recentBookingsData.result);
      }
    } catch (error) {
      console.error("Error fetching recent bookings:", error);
    }
  };

  const getDashboardBookingSummary = async () => {
    try {
      let bookinginfoData =
        await DashboardServices.getDashboardBookingSummary();
      console.log("DATA:" + JSON.stringify(bookinginfoData));
      if (bookinginfoData.status == true && bookinginfoData.result != null) {
        console.log("DATA:" + JSON.stringify(bookinginfoData.result[0]));

        //Refer the output json and form this json object
        const bookingdata = [
          {
            count: bookinginfoData.result[0].todaybookings,
            description: "Today's Bookings",
          },
          { count: bookinginfoData.result[0].waiting, description: "Waiting" },
          {
            count: bookinginfoData.result[0].upcoming,
            description: "UpComing",
          },
          { count: bookinginfoData.result[0].ongoing, description: "OnGoing" },
          { count: bookinginfoData.result[0].end, description: "Ended" },
          {
            count: bookinginfoData.result[0].completed,
            description: "Completed",
          },
          {
            count: bookinginfoData.result[0].cancelled,
            description: "Cancelled",
          },
          {
            count: bookinginfoData.result[0].rejected,
            description: "Rejected",
          },
          {
            count: bookinginfoData.result[0].oldrunning,
            description: "Old Running",
          },
        ];
        setDashboardBookingSummary(bookingdata);
      }
    } catch (error) {
      console.error("Error fetching booking summary:", error);
    }
  };

  useEffect(() => {
    getDashboardOverview();
    getDashboardBookingSummary();
    getDashboardRevenueSummary();
    getDashboardCustHostSummary();
    getDashboardVehicleSummary();
    getTodayNotifications();
    getDashboadRecentBookings();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const columns = [
    { id: "sno", label: "S.No", width: "5.5%" },
    { id: "customerName", label: "Customer Name", width: "20.5%" },
    { id: "car", label: "Car", width: "12.5%" },
    { id: "hostName", label: "Host Name", width: "12.5%" },
    { id: "bookingId", label: "Booking ID", width: "12.5%" },
    { id: "hoursBooked", label: "No of Hours Booked", width: "12.5%" },
    { id: "amount", label: "Amount", width: "12.5%" },
    { id: "tripStatus", label: "Trip Status", width: "12.5%" },
  ];

  const vehicledata = [
    { count: 200, description: "Approval Required" },
    { count: 100, description: "Active Vehicles" },
    { count: 50, description: "Blocked Vehicles" },
    { count: 75, description: "Non-Listing Vehicles" },
  ];

  const getCountColor = (count) => {
    if (count < 10) return "error.main"; // Red
    if (count > 50) return "success.main"; // Green
    return "common.black"; // White
  };

  const handleBlockClick = async (payload) => {
    // try {
    //   const response = await axios.post(apiEndpoint, payload);
    //   console.log(response.data);
    //   // Handle API response
    // } catch (error) {
    //   console.error(error);
    //   // Handle API error
    // }
  };

  return (
    <Container maxWidth="xl">
      <Grid2 item xs={12}>
        <Typography
          variant="h5"
          component="h5"
          gutterBottom
          sx={{ padding: 1, margin: 1 }}
        >
          Dashboard
        </Typography>
      </Grid2>
      <Grid2 container spacing={2} sx={{ width: "99%", margin: 1 }}>
        <Grid2 item xs={12} sm={6} md={3} sx={{ flex: 1 }}>
          <Card sx={{ padding: 1 }}>
            <CardContent>
              <Grid2
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid2 item>
                  <Typography variant="h7">
                    <b>Total Bookings</b>
                  </Typography>
                </Grid2>
                <Grid2 item>
                  <BookmarkAddIcon />
                </Grid2>
              </Grid2>
              <Box sx={{ display: "flex", alignItems: "left", marginTop: 2 }}>
                <Typography variant="h4">
                  {dashboarOverview.bookings}
                </Typography>
              </Box>
              <Box>
                <Typography variant="body2" color="text.secondary">
                  {dashboarOverview.bookingsDetails}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid2>

        <Grid2 item xs={12} sm={6} md={3} sx={{ flex: 1 }}>
          <Card sx={{ padding: 1 }}>
            <CardContent>
              <Grid2
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid2 item>
                  <Typography variant="h7">
                    <b>Total Revenue</b>
                  </Typography>
                </Grid2>
                <Grid2 item>
                  <CurrencyRupeeIcon />
                </Grid2>
              </Grid2>
              <Box sx={{ display: "flex", alignItems: "left", marginTop: 2 }}>
                <Typography variant="h4">{dashboarOverview.revenue}</Typography>
              </Box>
              <Box>
                <Typography variant="body2" color="text.secondary">
                  {dashboarOverview.revenueDetails}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid2>

        <Grid2 item xs={12} sm={6} md={3} sx={{ flex: 1 }}>
          <Card sx={{ padding: 1 }}>
            <CardContent>
              <Grid2
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid2 item>
                  <Typography variant="h7">
                    <b>Total Customers</b>
                  </Typography>
                </Grid2>
                <Grid2 item>
                  <GroupIcon />
                </Grid2>
              </Grid2>
              <Box sx={{ display: "flex", alignItems: "left", marginTop: 2 }}>
                <Typography variant="h4">
                  {dashboarOverview.customers}
                </Typography>
              </Box>
              <Box>
                <Typography variant="body2" color="text.secondary">
                  {dashboarOverview.customerDetails}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid2>

        <Grid2 item xs={12} sm={6} md={3} sx={{ flex: 1 }}>
          <Card sx={{ padding: 1 }}>
            <CardContent>
              <Grid2
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid2 item>
                  <Typography variant="h7">
                    <b>Total Hosts</b>
                  </Typography>
                </Grid2>
                <Grid2 item>
                  <PersonIcon />
                </Grid2>
              </Grid2>
              <Box sx={{ display: "flex", alignItems: "left", marginTop: 2 }}>
                <Typography variant="h4">{dashboarOverview.hosts}</Typography>
              </Box>

              <Box>
                <Typography variant="body2" color="text.secondary">
                  {dashboarOverview.hostDetails}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid2>
        <Grid2 item xs={12} sm={6} md={3} sx={{ flex: 1 }}>
          <Card sx={{ padding: 1 }}>
            <CardContent>
              <Grid2
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid2 item>
                  <Typography variant="h7">
                    <b>Total Vehicles</b>
                  </Typography>
                </Grid2>
                <Grid2 item>
                  <PersonIcon />
                </Grid2>
              </Grid2>
              <Box sx={{ display: "flex", alignItems: "left", marginTop: 2 }}>
                <Typography variant="h4">
                  {dashboarOverview.vehicles}
                </Typography>
              </Box>

              <Box>
                <Typography variant="body2" color="text.secondary">
                  {dashboarOverview.vehicleDetails}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid2>
      </Grid2>
      <Card sx={{ height: 140, padding: 1, margin: 1 }}>
        <Typography variant="h6" fontWeight="bold">
          Booking Summary
        </Typography>
        <Divider sx={{ marginBottom: 2, marginTop: 1 }} />
        <Grid2
          container
          display="flex"
          justifyContent="space-around"
          alignItems="center"
        >
          {dashboardBookingSummary.map((item, index) => (
            <React.Fragment key={index}>
              <Grid2
                item
                xs={1.5}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                sx={{ cursor: "pointer" }}
                onClick={() => handleBlockClick(item.description)}
              >
                <Typography
                  variant="h4"
                  color={getCountColor(item.count)}
                  fontWeight="bold"
                >
                  {item.count}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {item.description}
                </Typography>
              </Grid2>
              {index < dashboardBookingSummary.length - 1 && (
                <Divider orientation="vertical" flexItem />
              )}
            </React.Fragment>
          ))}
        </Grid2>
      </Card>
      <Card sx={{ height: 140, padding: 1, margin: 1 }}>
        <Typography variant="h6" fontWeight="bold">
          Revenue Summary
        </Typography>
        <Divider sx={{ marginBottom: 2, marginTop: 1 }} />
        <Grid2
          container
          display="flex"
          justifyContent="space-around"
          alignItems="center"
        >
          {dashboardRevenueSummary.map((item, index) => (
            <React.Fragment key={index}>
              <Grid2
                item
                xs={1.5}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                sx={{ cursor: "pointer" }}
                onClick={() => handleBlockClick(item.description)}
              >
                <Typography
                  variant="h4"
                  color={getCountColor(item.count)}
                  fontWeight="bold"
                >
                  {item.count}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {item.description}
                </Typography>
              </Grid2>
              {index < dashboardRevenueSummary.length - 1 && (
                <Divider orientation="vertical" flexItem />
              )}
            </React.Fragment>
          ))}
        </Grid2>
      </Card>
      <Card sx={{ height: 140, padding: 1, margin: 1 }}>
        <Typography variant="h6" fontWeight="bold">
          Customer Host Summary
        </Typography>
        <Divider sx={{ marginBottom: 2, marginTop: 1 }} />
        <Grid2
          container
          display="flex"
          justifyContent="space-around"
          alignItems="center"
        >
          {dashboardCustomerHostSummary.map((item, index) => (
            <React.Fragment key={index}>
              <Grid2
                item
                xs={1.5}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                sx={{ cursor: "pointer" }}
                onClick={() => handleBlockClick(item.description)}
              >
                <Typography
                  variant="h4"
                  color={getCountColor(item.count)}
                  fontWeight="bold"
                >
                  {item.count}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {item.description}
                </Typography>
              </Grid2>
              {index < dashboardCustomerHostSummary.length - 1 && (
                <Divider orientation="vertical" flexItem />
              )}
            </React.Fragment>
          ))}
        </Grid2>
      </Card>
      <Card sx={{ height: 140, padding: 1, margin: 1 }}>
        <Typography variant="h6" fontWeight="bold">
          Vehicle Summary
        </Typography>
        <Divider sx={{ marginBottom: 2, marginTop: 1 }} />
        <Grid2
          container
          display="flex"
          justifyContent="space-around"
          alignItems="center"
        >
          {dashboardVehicleSummary.map((item, index) => (
            <React.Fragment key={index}>
              <Grid2
                item
                xs={1.5}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                sx={{ cursor: "pointer" }}
                onClick={() => handleBlockClick(item.description)}
              >
                <Typography
                  variant="h4"
                  color={getCountColor(item.count)}
                  fontWeight="bold"
                >
                  {item.count}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {item.description}
                </Typography>
              </Grid2>
              {index < dashboardVehicleSummary.length - 1 && (
                <Divider orientation="vertical" flexItem />
              )}
            </React.Fragment>
          ))}
        </Grid2>
      </Card>

      <Grid2 container spacing={2} sx={{ margin: 1, marginBottom: 5 }}>
        <Grid2 item xs={12} sm={6} md={6} lg={6}>
          <Card sx={{ height: 400, overflowY: "auto" }}>
            <CardContent>
              <Typography
                variant="h6"
                fontWeight="bold"
                sx={{
                  position: "sticky",
                  top: 0,
                  backgroundColor: "white",
                  zIndex: 1,
                  padding: 2,
                  borderBottom: "1px solid #ddd",
                }}
              >
                Todays Bookings
              </Typography>
              <Divider sx={{ marginBottom: 2, marginTop: 0 }} />
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Sno</TableCell>
                    <TableCell>Booking ID</TableCell>
                    <TableCell>Vehicle</TableCell>
                    <TableCell>StartDate</TableCell>
                    <TableCell>EndDate</TableCell>
                    <TableCell>Customer</TableCell>
                    <TableCell>CustomerMobile</TableCell>
                    <TableCell>Host</TableCell>
                    <TableCell>HostMobile</TableCell>
                    <TableCell>VehicleModel</TableCell>
                    <TableCell>BookingStatus</TableCell>
                    <TableCell>GrandTotal</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {recentBookings.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={12} align="center">
                        No Booking today
                      </TableCell>
                    </TableRow>
                  ) : (
                    recentBookings.slice(0, 10).map((row) => (
                      <TableRow
                        key={row.sno}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.id}
                        </TableCell>
                        <TableCell>{row.bookingid}</TableCell>
                        <TableCell>{row.vehiclenumber}</TableCell>
                        <TableCell>
                          {new Date(row.plannedStartDateTime).toLocaleString(
                            "en-GB",
                            {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                              hour: "2-digit",
                              minute: "2-digit",
                            }
                          )}
                        </TableCell>
                        <TableCell>
                          {new Date(row.plannedEndDateTime).toLocaleString(
                            "en-GB",
                            {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                              hour: "2-digit",
                              minute: "2-digit",
                            }
                          )}
                        </TableCell>
                        <TableCell>{row.customername}</TableCell>
                        <TableCell>{row.customermobile}</TableCell>
                        <TableCell>{row.hostname}</TableCell>
                        <TableCell>{row.hostmobile}</TableCell>
                        <TableCell>{row.vehiclemodel}</TableCell>
                        <TableCell>{row.bookingstatus}</TableCell>
                        <TableCell>{row.grandtotal}</TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
              {/* {recentBookings.length > 10 && (
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ marginTop: 2 }}
                  >
                    Scroll down to view more records...
                  </Typography>
                )} */}
            </CardContent>
          </Card>
        </Grid2>
        <Grid2 item xs={12} sm={6} md={6} lg={6}>
          <Card sx={{ height: 400, overflowY: "auto", width: "100%" }}>
            <CardContent>
              <Grid2
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography
                  variant="h6"
                  fontWeight="bold"
                  sx={{
                    position: "sticky",
                    top: 0,
                    backgroundColor: "white",
                    zIndex: 1,
                    padding: 1,
                    borderBottom: "1px solid #ddd",
                  }}
                >
                  Notifications
                </Typography>
                <RefreshIcon
                  sx={{ cursor: "pointer" }}
                  onClick={getTodayNotifications}
                />
              </Grid2>
              <Divider sx={{ marginBottom: 2, marginTop: 0 }} />
              {todayNotifications.length === 0 ? (
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ textAlign: "center", marginTop: 2 }}
                >
                  No Notifications Today
                </Typography>
              ) : (
                todayNotifications.slice(0, 10).map((notification, index) => (
                  <ListItem key={index} sx={{ padding: 0, margin: 1 }}>
                    <Card sx={{ width: "100%", padding: 0 }}>
                      <CardContent>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography variant="h6">
                            {notification.title}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{ textAlign: "right" }}
                          >
                            {new Date(notification.sentdatetime).toLocaleString(
                              "en-GB",
                              {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric",
                                hour: "2-digit",
                                minute: "2-digit",
                              }
                            )}
                          </Typography>
                        </Box>

                        <Typography variant="body2" color="text.secondary">
                          {notification.body}
                        </Typography>
                      </CardContent>
                    </Card>
                  </ListItem>
                ))
              )}
              {todayNotifications.length > 10 && (
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ marginTop: 2 }}
                >
                  Scroll down to view more notifications...
                </Typography>
              )}
            </CardContent>
          </Card>
        </Grid2>
      </Grid2>
      <Typography
        variant="body2"
        color="text.secondary"
        sx={{
          position: "fixed",
          bottom: 0,
          right: 0,
          left: 0,
          textAlign: "center",
          backgroundColor: "white",
          padding: 1,
          zIndex: 1,
          borderColor: "divider",
          borderTop: 1,
        }}
      >
        &copy; {new Date().getFullYear()} All Rights Reserved.
      </Typography>
      <ToastContainer />
    </Container>
  );
};

export default Dashboard;
